import IconImage from '@assets/icon-image.svg';
import {
  TypoSBold,
  TypoXSBold,
  TypoXXS,
  TypoXXSBold,
  TypoXXXSBold,
} from '@atoms/Typos';
import Link from '@components/Link';
import ProfileIcon from '@molecules/ProfileIcon';
import { TravelStoryListItem } from '@services/hafh/types/generated';
import IconBed from '@svg-icons/IconBed';
import { LOCALIZED_MONTH_AND_YEAR_FORMAT } from '@utils/date';
import routes, { route } from '@utils/routes';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

const TravelStoryCard = ({
  isListView = false,
  isPropertyTravelStory = false,
  travelStory,
}: {
  isListView?: boolean;
  isPropertyTravelStory?: boolean;
  travelStory: TravelStoryListItem;
}) => {
  const { lang, t } = useTranslation('travel-story');

  if (!travelStory) {
    return null;
  }

  const stayDate = dayjs(travelStory.stays?.[0]?.month_unformatted).isValid()
    ? dayjs(travelStory.stays?.[0]?.month_unformatted).format(
        LOCALIZED_MONTH_AND_YEAR_FORMAT[lang] || 'YYYY-MM'
      )
    : null;

  return (
    <Link
      href={
        travelStory?.status === 'draft' // only my stories have status in TravelStoryListItem
          ? `${routes.travelStoryForm}?travel_story_id=${travelStory.id}`
          : route(routes.travelStoryDetail, {
              travelStoryId: travelStory.id,
            })
      }
    >
      <div
        className={classNames('travel-story-card', {
          'list-view': isListView,
        })}
      >
        <div className="card-thumbnail">
          <div className="card-thumbnail-image-cont">
            <Image
              alt={travelStory.title || 'travel-story-thumbnail'}
              className="banner-slider-image"
              fill={true}
              src={travelStory.thumbnail_url}
              style={{
                objectFit: 'cover',
              }}
            />
            <div className="thumbnail-image-overlay" />
            {!isPropertyTravelStory && (
              <div className="property-prefecture">
                <TypoXXSBold
                  color="white"
                  text={travelStory.stays[0].property.prefecture}
                />
              </div>
            )}
            <div className="image-info">
              <IconImage />
              <TypoXXSBold
                color="white"
                text={travelStory.total_images_count || 0}
              />
            </div>
            <div className="thumbnail-info">
              <div className="title">
                {isListView ? (
                  <TypoSBold color="white" text={travelStory.title || ''} />
                ) : (
                  <TypoXSBold color="white" text={travelStory.title || ''} />
                )}
              </div>
              <div className="row">
                <div className="author-info">
                  <ProfileIcon neighbor={travelStory.neighbor} size="sm" />
                  <div className="author-info-text-cont">
                    <TypoXXSBold
                      color="white"
                      text={travelStory.neighbor?.nickname || ''}
                    />
                    {stayDate && (
                      <div>
                        <TypoXXXSBold
                          color="white"
                          text={t('card.stayDate', {
                            date: stayDate,
                          })}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="story-info">
            <div
              className={classNames('desc', {
                'property-travel-story': isPropertyTravelStory,
              })}
            >
              <TypoXXS
                color="black"
                text={travelStory.stays[0].description || ''}
              />
            </div>
            {!isPropertyTravelStory && (
              <div className="property-name">
                <IconBed size="xs" strokeColor="black600" withFill={false} />
                <TypoXXS
                  color="black600"
                  text={travelStory.stays[0].property.name || ''}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <style jsx={true}>{styles}</style>
    </Link>
  );
};

export default TravelStoryCard;
