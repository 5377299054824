import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .travel-story-card {
    border-radius: 8px;
    box-shadow:
      0px 2px 4px -2px rgba(24, 39, 75, 0.12),
      0px 4px 4px -2px rgba(24, 39, 75, 0.08);
    overflow: hidden;
    transition: all 0.3s;
    &:hover {
      box-shadow: 0 8px 8px -2px rgba(24, 39, 75, 0.12);
    }
    &.list-view {
      .story-info {
        height: 104px;
        .desc {
          :global(span) {
            -webkit-line-clamp: 3 !important;
          }
        }
      }
    }

    .card-thumbnail {
      .card-thumbnail-image-cont {
        position: relative;
        height: 168px;
        width: 100%;
        .thumbnail-image-overlay {
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          left: 0;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.1) 0%,
            rgba(0, 0, 0, 0.6) 100%
          );
        }
        .property-prefecture {
          position: absolute;
          top: 12px;
          left: 12px;
          background: rgba(0, 0, 0, 0.4);
          padding: 2px 4px;
          border-radius: 4px;
        }
        .image-info {
          position: absolute;
          top: 12px;
          right: 12px;
          display: flex;
          gap: 2px;
          align-items: center;
          background: rgba(0, 0, 0, 0.4);
          padding: 2px 4px;
          border-radius: 4px;
        }
        .thumbnail-info {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 12px;
          .title {
            word-break: break-word;
          }
          .row {
            margin-top: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .author-info {
              display: flex;
              gap: 12px;
              align-items: center;
              margin-top: 4px;
            }
          }
        }
      }
    }
    .story-info {
      padding: 12px;
      height: 104px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: ${COLORS.white};
      .desc {
        :global(span) {
          word-break: break-word;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          white-space: normal;
          -webkit-line-clamp: 3;
        }
        &.property-travel-story {
          :global(span) {
            -webkit-line-clamp: 4;
          }
        }
      }
      .property-name {
        display: flex;
        gap: 4px;
        align-items: center;

        :global(span) {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal;
          -webkit-line-clamp: 1;
        }
      }
    }
  }
`;

export default styles;
