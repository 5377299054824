import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  :global(.fill-black600) {
    :global(path) {
      fill: ${COLORS.black600};
    }
  }
`;

export default styles;
